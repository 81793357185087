import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpParams,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { Observable, from, lastValueFrom, throwError } from 'rxjs';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { DataService } from './data.service';
import { AuthService } from './auth/auth.service';
import { environment } from '../../environments/environment';

type HttpOptions = { headers: HttpHeaders; params?: HttpParams; responseType?: any };

class DittoSearchHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  encodeValue(v: string): string {
    if (v.includes('sort(+')) {
      // get '%2B' as replacement for '+' out of angular
      // required by the ditto http api
      return encodeURIComponent(v);
    } else {
      return super.encodeValue(v);
    }
  }
}

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // allow translations of login page to be always able to be retrieved and shown to users.
    if (request.url.includes(environment.translations)) {
      return next.handle(request);
    } else {
      return from(this.handle(request, next));
    }
  }

  async handle(request: HttpRequest<unknown>, next: HttpHandler) {
    const options = await this.autoAuthRequest(request.urlWithParams, '', undefined, undefined, request.headers);
    const req = request.clone({
      headers: options.headers,
      params: options.params,
      body: request.body,
      url: request.url,
    });
    return lastValueFrom(next.handle(req));
  }

  autoAuthRequest(
    urlSuffix: string,
    payload?: any,
    httpParams?: { [key: string]: any },
    responseType?: string,
    additionalHeaders?: HttpHeaders,
  ): Promise<HttpOptions> {
    return this.autoAuthRequestObservable(urlSuffix, payload, httpParams, responseType, additionalHeaders);
  }

  private static getHttpOptions(
    accessToken: string,
    httpParams?: { [key: string]: any },
    additionalHeaders?: HttpHeaders,
  ): HttpOptions {
    const headers: {
      [name: string]: string | string[];
    } = {
      Authorization: 'Bearer ' + accessToken,
    };
    const httpOptions: HttpOptions = {
      headers: new HttpHeaders(headers),
    };
    additionalHeaders?.keys().forEach((element) => {
      const value = additionalHeaders.get(element);
      if (value) {
        headers[element] = value;
      }
    });
    if (httpParams) {
      let params = new HttpParams({
        encoder: new DittoSearchHttpUrlEncodingCodec(),
      });
      for (const param in httpParams) {
        const value = httpParams[param];
        params = params.set(param, value);
      }
      httpOptions.params = params;
    }
    return httpOptions;
  }

  autoAuthRequestObservable(
    urlSuffix: string,
    payload?: any,
    httpParams?: { [key: string]: any },
    responseType?: string,
    additionalHeaders?: HttpHeaders,
  ): Promise<HttpOptions> {
    return new Promise((resolve, reject) => {
      const currentUser = this.authService.getCurrentUser();
      if (currentUser) {
        currentUser.getSession(function (err: Error | null, session: CognitoUserSession | null) {
          if (err) {
            reject(err);
          } else if (!session) {
            reject('No session available');
          } else {
            const httpOptions = Interceptor.getHttpOptions(
              session.getAccessToken().getJwtToken(),
              httpParams,
              additionalHeaders,
            );
            resolve(httpOptions);
          }
        });
      } else {
        reject({ error: { message: DataService.USER_NOT_FOUND_MESSAGE } });
      }
    });
  }
  handleError(err: any): Observable<any> {
    let errorMessage = err.error ? err.error.message : `Error Code: ${err.status}\nMessage: ${err.message}`;

    if (
      errorMessage?.includes(DataService.SESSION_EXPIRED_MESSAGE) ||
      errorMessage == DataService.USER_NOT_FOUND_MESSAGE
    ) {
      this.authService.logout();
    }

    try {
      const httpError = JSON.parse(err.error);
      errorMessage = httpError.message;
    } catch (e) {}
    return throwError(() => new Error(errorMessage));
  }
}
