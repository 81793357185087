export const environment = {
  production: false,
  qa: false,
  cognitoUserPoolId: 'eu-central-1_RD6WaBL3v',
  cognitoClientId: '2eed9aejrop6r57ejd8iftnccg',
  // services urls
  cognitoUrl: 'https://cognito-idp.eu-central-1.amazonaws.com',
  dittoUrl: 'https://ditto.develop.beyonnex.org/',
  servicesApiUrl: 'https://api.develop.beyonnex.org/',
  serviceIdentifier: 'identifier/',
  serviceMBusParserDecode: 'mbus-parser/decode',
  serviceElsCsvImporter: 'mbus-parser/',
  serviceDevicesRepo: 'device-repo/devicetypes',
  serviceTemplateRenderer: 'template-renderer',
  mbusgateway: 'mbus-gateway',
  serviceDitto: 'api/2/',
  fuotaProcessor: 'fuota-processor/api/rollouts',
  foutaGroups: 'fuota-processor/api/rollouts/groups',
  serviceHistorian: 'historian',
  qsmpproxy: 'wfms/qsmp-proxy',
  maintenanceOrderAction: 'maintenance-orders/actions',
  retrieveMaintenanceOrder: 'maintenance-orders/orders',
  singleMaintenanceOrder: 'maintenance-orders/order',
  readouts: 'readouts',
  translations: '/assets/i18n',
  listDevices: 'search/things',
  listBE: 'business-entities',
  serviceTimeseries: 'ts/series',
  serviceFleet: 'fleet/api/v1',
  serviceFuota: 'fuota',
  serviceNewFuota: 'fuota-processor/api',
};

// Uncomment to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask` (not recommended for production).
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
