import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { parse as jsonBigIntParse } from 'json-bigint';
import { catchError, firstValueFrom, map, Observable, Observer, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';

type HttpOptions = { headers?: HttpHeaders; params?: HttpParams; responseType?: any };

class DittoSearchHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  encodeValue(v: string): string {
    if (v.includes('sort(+')) {
      // get '%2B' as replacement for '+' out of angular
      // required by the ditto http api
      return encodeURIComponent(v);
    } else {
      return super.encodeValue(v);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  static readonly SESSION_EXPIRED_MESSAGE = 'Refresh Token has expired';
  static readonly USER_NOT_FOUND_MESSAGE = 'No current user could be found';

  static API_URL = environment.servicesApiUrl;
  static DITTO_API_URL = environment.dittoUrl;

  static SERVICES = {
    identifier: DataService.API_URL + environment.serviceIdentifier,
    mbusgateway: DataService.API_URL + environment.mbusgateway,
    mbusParserDecode: DataService.API_URL + environment.serviceMBusParserDecode,
    elsCsvImporter: DataService.API_URL + environment.serviceElsCsvImporter,
    devicesRepo: DataService.API_URL + environment.serviceDevicesRepo,
    templateRenderer: DataService.API_URL + environment.serviceTemplateRenderer,
    ditto: DataService.DITTO_API_URL + environment.serviceDitto,
    fuotaProcessor: DataService.API_URL + environment.fuotaProcessor,
    foutaGroups: DataService.API_URL + environment.foutaGroups,
    retrieveMaintenanceOrder: DataService.API_URL + environment.retrieveMaintenanceOrder,
    maintenanceOrderAction: DataService.API_URL + environment.maintenanceOrderAction,
    singleMaintenanceOrder: DataService.API_URL + environment.singleMaintenanceOrder,
    historian: DataService.API_URL + environment.serviceHistorian,
    qsmpproxy: DataService.API_URL + environment.qsmpproxy,
    readouts: DataService.API_URL + environment.readouts,
    timeseries: DataService.API_URL + environment.serviceTimeseries,
    fleet: DataService.API_URL + environment.serviceFleet,
    fuota: DataService.API_URL + environment.serviceFuota,
    serviceNewFuota: DataService.API_URL + environment.serviceNewFuota,
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  private static getHttpOptions(accessToken: string, httpParams?: { [key: string]: any }): HttpOptions {
    const httpOptions: HttpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + accessToken,
      }),
    };
    if (httpParams) {
      let params = new HttpParams({
        encoder: new DittoSearchHttpUrlEncodingCodec(),
      });
      for (const param in httpParams) {
        const value = httpParams[param];
        params = params.set(param, value);
      }
      httpOptions.params = params;
    }
    return httpOptions;
  }

  getBlobData(http: HttpClient, url: string, httpOptions: HttpOptions): Observable<any> {
    httpOptions.responseType = 'blob';
    return http.get<any>(url, httpOptions);
  }

  public getApiRequest(
    endpoint: string,
    params: object = {},
    headers: { [header: string]: string | string[] } = {},
  ): Observable<any> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key as keyof typeof params]);
    });
    let getHeaders = new HttpHeaders();
    Object.keys(headers).forEach(function (key) {
      getHeaders = getHeaders.set(key, headers[key as keyof typeof headers]);
    });
    return this.http.get(endpoint, { params: httpParams, headers: getHeaders });
  }

  public deleteApiRequest(endpoint: string, params: any = {}): Observable<any> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key as keyof typeof params]);
    });
    return this.http.delete(endpoint, { body: httpParams });
  }

  public postApiRequest(
    endpoint: string,
    payload?: any,
    params: object = {},
    responseType: string = 'text',
    extraHeaders?: object,
  ): Observable<any> {
    let httpParams = new HttpParams();
    let headers = new HttpHeaders();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key as keyof typeof params]);
    });
    if (extraHeaders && Object.keys(extraHeaders).length) {
      Object.entries(extraHeaders).forEach(([k, v]) => {
        headers = headers.append(k, v);
      });
    }
    return this.http.post(endpoint, payload, {
      params: httpParams,
      headers: headers,
      responseType: responseType as 'json',
    });
  }

  public putApiRequest(endpoint: string, payload?: any, params: object = {}): Observable<any> {
    let httpParams = new HttpParams();
    const headers = new HttpHeaders();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key as keyof typeof params]);
    });
    return this.http.put(endpoint, payload, { params: httpParams, headers: headers });
  }

  public patchApiRequest(endpoint: string, payload?: any, params: object = {}): Observable<any> {
    let httpParams = new HttpParams();
    const headers = new HttpHeaders();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key as keyof typeof params]);
    });
    return this.http.patch(endpoint, payload, { params: httpParams, headers: headers });
  }

  getDataResultBigInt(
    http: HttpClient,
    url: string,
    httpOptions: HttpOptions,
    payload: any,
    responseType: string = 'text',
  ): Observable<any> {
    httpOptions.responseType = responseType;

    return http.get<any>(url, httpOptions).pipe(map((result) => jsonBigIntParse(result)));
  }

  autoAuthRequest(
    reqFunction: (
      http: HttpClient,
      url: string,
      httpOptions: HttpOptions,
      payload?: any,
      responseType?: string,
    ) => Observable<any>,
    urlSuffix: string,
    payload?: any,
    httpParams?: { [key: string]: any },
    responseType?: string,
  ): Promise<any> {
    return firstValueFrom(this.autoAuthRequestObservable(reqFunction, urlSuffix, payload, httpParams, responseType));
  }

  autoAuthRequestObservable(
    reqFunction: (
      http: HttpClient,
      url: string,
      httpOptions: HttpOptions,
      payload?: any,
      responseType?: string,
    ) => Observable<any>,
    urlSuffix: string,
    payload?: any,
    httpParams?: { [key: string]: any },
    responseType?: string,
  ): Observable<any> {
    const currentUser = this.authService.getCurrentUser();
    const dataService = this;
    if (currentUser) {
      return new Observable((observer: Observer<any>) => {
        currentUser.getSession(function (err: Error | null, session: CognitoUserSession | null) {
          if (err) {
            observer.error(err);
          } else if (!session) {
            observer.error('No session available');
          } else {
            const httpOptions = DataService.getHttpOptions(session.getAccessToken().getJwtToken(), httpParams);
            reqFunction(dataService.http, urlSuffix, httpOptions, payload, responseType).subscribe({
              next: (data: any) => observer.next(data),
              error: (err: any) => observer.error(err),
              complete: () => observer.complete(),
            });
          }
        });
      }).pipe(catchError((err) => this.handleError(err)));
    } else {
      return this.handleError({ error: { message: DataService.USER_NOT_FOUND_MESSAGE } });
    }
  }

  handleError(err: any): Observable<any> {
    let errorMessage = err.error ? err.error.message : `Error Code: ${err.status}\nMessage: ${err.message}`;

    if (
      errorMessage?.includes(DataService.SESSION_EXPIRED_MESSAGE) ||
      errorMessage == DataService.USER_NOT_FOUND_MESSAGE
    ) {
      this.authService.logout();
    }
    try {
      const httpError = JSON.parse(err.error);
      errorMessage = httpError.message;
    } catch (e) {}
    return throwError(() => new Error(errorMessage));
  }
}
